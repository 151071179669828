import React, { useContext } from "react";
import { isEmpty } from 'lodash';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { getOgImage } from "../utils/functions";
import ThemeContext from "../context/ThemeContext";
import A from 'gatsby-link'

const SingleProject = ( props ) => {
	const {lang} =useContext(ThemeContext)
	const { pageContext: { node: {title, seo, uri, translations} } } = props;

	return (
		<Layout className={'container'}>
			{
				!isEmpty( props.pageContext ) ? (
					<>
						<SEO
							title={ title }
							seoData={ seo }
							uri={ uri }
							header={ { siteTitle: 'Gatsby WooCommerce Theme' } }
							openGraphImage={ getOgImage( seo ) }
						/>
						<h1>{title}</h1>
						{translations && translations.map(translateObj => <A to={translateObj.uri}>{translateObj.language.slug}</A>)}
					</>
				) : (
					<div>Something went wrong</div>
				)
			}
		</Layout>
	)
};
export default SingleProject;

